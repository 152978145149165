import Routes from "../constant/Routes";
import { BearerTOKEN } from "../constant/constants";


export async function loginAdmin(id, password){
    const fullPath = Routes.apiAdminUrl + 'auth/login';
    try {
        const response = await fetch(fullPath, {
            method: "POST",
            headers: {
                "customer": "admin",
                "lang": "en",
                "Content-Type": "application/json",
                accept: "application/json",
            },
            body: JSON.stringify({appVersion: Routes.appVersion, email: id, password: password}) 
        });
    
        if (!response.ok) {
            throw new Error('Failed to fetch post loginAdmin');
        }

        const responseJson = await response.json();
        return responseJson;
    } catch (error) {
        return error;
    }
}

export async function listRoles(data ={}){
    const queryParams = new URLSearchParams(data).toString();

    const fullPath = Routes.apiAdminUrl + 'roles?'+queryParams;
    const token =  localStorage.getItem("token");
    try {
        const response = await fetch(fullPath, {
            method: "GET",
            headers: {
                "customer": "admin",
                "lang": "en",
                "Content-Type": "application/json",
                accept: "application/json",
                Authorization: `Bearer ${token}`,
            }
        });
    
        if (!response.ok) {
            throw new Error('Failed to fetch post loginAdmin');
        }

        const responseJson = await response.json();
        return responseJson;
    } catch (error) {
        return error;
    }
}

export async function apiGet(url, data ={}){
    const queryParams = new URLSearchParams(data).toString();
    const fullPath = url + '?'+queryParams;
    try {
        const response = await fetch(fullPath, {
            method: "GET",
            headers: {
                "customer": "admin",
                "lang": "en",
                "Content-Type": "application/json",
                accept: "application/json",
                Authorization: BearerTOKEN(),
            }
        });
    
        if (!response.ok) {
            throw new Error('Failed to fetch GET ');
        }

        const responseJson = await response.json();
        return responseJson;
    } catch (error) {
        return error;
    }
}

export async function apiPost(url, data ={}){    
    try {
        const response = await fetch(url, {
            method: "POST",
            headers: {
                "customer": "admin",
                "lang": "en",
                "Content-Type": "application/json",
                accept: "application/json",
                Authorization: BearerTOKEN(),
            },
            body: JSON.stringify(data)
        });
    
        if (!response.ok) {
            throw new Error('Failed to fetch Delete ');
        }

        const responseJson = await response.json();
        return responseJson;
    } catch (error) {
        return error;
    }
}

export async function apiPut(url, data ={}){    
    try {
        const response = await fetch(url, {
            method: "PUT",
            headers: {
                "customer": "admin",
                "lang": "en",
                "Content-Type": "application/json",
                accept: "application/json",
                Authorization: BearerTOKEN(),
            },
            body: JSON.stringify(data)
        });
    
        if (!response.ok) {
            throw new Error('Failed to fetch Delete ');
        }

        const responseJson = await response.json();
        return responseJson;
    } catch (error) {
        return error;
    }
}

export async function apiDelete(url, data ={}){    
    try {
        const response = await fetch(url, {
            method: "Delete",
            headers: {
                "customer": "admin",
                "lang": "en",
                "Content-Type": "application/json",
                accept: "application/json",
                Authorization: BearerTOKEN(),
            },
            body: JSON.stringify(data)
        }); 
    
        if (!response.ok) {
            throw new Error('Failed to fetch Delete ');
        }

        const responseJson = await response.json();
        return responseJson;
    } catch (error) {
        return error;
    }
}