import React, { useState, useEffect } from "react";
import logo from "../assets/images/endless-logo.png";
import man from "../assets/images/user/1.jpg";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { useNavigate } from "react-router-dom";
import { loginAdmin } from "../api/http";
import AppLangKeys from "../localization/AppLangKeys";
import { useTranslation } from "react-i18next";

const Signin = () => {
    const { t } = useTranslation();
  const [isFetching, setisFetching] = useState(false);

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");


  const navigate = useNavigate();

  const [value, setValue] = useState(localStorage.getItem("profileURL" || man));

  useEffect(() => {
    if (value !== null) localStorage.setItem("profileURL", value);
    else localStorage.setItem("profileURL", man);
  }, [value]);

  const loginAuth = async (e) => {
    setisFetching(true);
    const responseJson = await loginAdmin(email, password);
    if(responseJson.status){
      localStorage.setItem("login", true);
      localStorage.setItem("token", responseJson.data.access_token);
      localStorage.setItem("current_login_name", responseJson.data.current_login_name);
      navigate(`${process.env.PUBLIC_URL}/dashboard/customers`);
    }else{
      toast.error(responseJson.message);
    }
    setisFetching(false);
  };

  return (
    <div>
      <div className="page-wrapper" style={{ overflow: "hidden" }}>
        <div className="container-fluid p-0">
          {/* <!-- login page start--> */}
          <div className="authentication-main">
            <div className="row">
              <div className="col-md-12">
                <div className="auth-innerright">
                  <div className="authentication-box">
                    <div className="text-center">
                      <img src={logo} alt="" />
                    </div>
                      <div className="card mt-4 p-50">
                      <div className="card-body">
                        <div className="text-center">
                          <h4>{t(AppLangKeys.LOGIN)}</h4>
                          <h6>{t(AppLangKeys.enterYourUsernameAndPassword)}</h6>
                        </div>
                        <form className="theme-form">
                          <div className="form-group">
                            <label className="col-form-label pt-0">{t(AppLangKeys.YourName)}</label>
                            <input className="form-control" type="email" name="email" value={email} onChange={(e) => setEmail(e.target.value)} placeholder={t(AppLangKeys.YourName)} />
                          </div>
                          <div className="form-group">
                            <label className="col-form-label">{t(AppLangKeys.Password)}</label>
                            <input className="form-control" type="password" name="password" value={password} onChange={(e) => setPassword(e.target.value)} />
                          </div>
                          {/* <div className="checkbox p-0">
                            <input id="checkbox1" type="checkbox" />
                            <label htmlFor="checkbox1">{RememberMe}</label>
                          </div> */}
                          <div className="form-group form-row mt-3 mb-0 d-grid">
                            {!isFetching && <button className="btn btn-danger" type="button" onClick={() => loginAuth()}>
                              {t(AppLangKeys.LOGIN)}
                            </button>}

                            {isFetching && <div className="loader-box">
                            <div className="loader" style={{margin:"auto"}}>
                              <div className="line bg-danger"></div>
                              <div className="line bg-danger"></div>
                              <div className="line bg-danger"></div>
                              <div className="line bg-danger"></div>
                            </div>
                          </div>}
                          </div>
                          {/* <div className="login-divider"></div>
                          <div className="social mt-3">
                            <div className="form-group btn-showcase d-flex">
                              <button className="btn social-btn btn-fb d-inline-block" type="button" onClick={facebookAuth}>
                                {" "}
                                <i className="fa fa-facebook"></i>
                              </button>
                              <button className="btn social-btn btn-twitter d-inline-block" type="button" onClick={googleAuth}>
                                <i className="fa fa-google"></i>
                              </button>
                              <button className="btn social-btn btn-google d-inline-block" type="button" onClick={twitterAuth}>
                                <i className="fa fa-twitter"></i>
                              </button>
                              <button className="btn social-btn btn-github d-inline-block" type="button" onClick={githubAuth}>
                                <i className="fa fa-github"></i>
                              </button>
                            </div>
                          </div> */}
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <ToastContainer />
          {/* <!-- login page end--> */}
        </div>
      </div>
    </div>
  );
};

export default Signin;

