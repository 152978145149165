
 const  AppCaches = {
    token : "token",
    customer : "customer",
    login : "login",
    loginData : "loginData",
    loginName : "loginName",
    lang : "lang",
}

export default AppCaches;