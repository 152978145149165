import { Typeahead } from "react-bootstrap-typeahead";
import SaveCancelModel from "../../../app-components/SaveCancelModel";
import { useEffect, useState } from "react";
import { apiGet, apiPost, apiPut, listRoles } from "../../../api/http";
import { toast } from "react-toastify";
import Routes from "../../../constant/Routes";
import { useTranslation } from "react-i18next";
import AppLangKeys from "../../../localization/AppLangKeys";

function CreateTenant({
  handleList,
  toggleHandler,
  title,
  isOpen,
  isEdit,
  row,
}) {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);
  const [roleOptions, setRoleOptions] = useState([row?.role]);
  const [selectedRole, setSelectedRole] = useState(row?.role);
  const [name, setName] = useState(row?.name ?? "");
  const [isActive, setIsActive] = useState((row?.isActive ?? 0) == 0? false : true);
  const [errors, setErrors] = useState({});

  const [queryParams, setQueryParams] = useState({
    paginationCounter: 10,
    page: 1,
    sortColumnName: "name",
    sortDirection: "asc",
    glopaleSearch: "",
  });

  function resetForm() {
    setLoading(false);
    setSelectedRole("");
    setName("");
    setIsActive(false);
    setErrors({});
    if (roleOptions.length < 1) {
      handleListRoles();
    }
  }

  useEffect(() => {
    handleListRoles();
  }, [row]);

  useEffect(() => {
    setRoleOptions([row?.role]);
    setSelectedRole(row?.role);
    setIsActive((row?.isActive ?? 0) == 0? false : true);
    setName(row?.name ?? "");
  }, [row]);

  function onNameChangeHandler(event) {
    const newName = event.target.value;
    setName(newName);
    let newErrors = {};
    if (newName.trim == "" || newName.length < 4) {
      newErrors = {
        ...errors,
        name: [t(AppLangKeys.tenantNameMustBeGraterThan_3Letters)],
      };
    } else if (errors.name?.length > 0) {
      newErrors = {
        ...errors,
        name: [],
      };
    }
    setErrors(newErrors);
    return newErrors;
  }

  async function handleListRoles(queryData) {
    setLoading(true);
    const responseJson = await apiGet(Routes.ROLES, queryData ?? queryParams);
    if (responseJson.status) {
      let newRoleOptions = responseJson.data?.roles?.data?.map(
        (role) => role.name
      );
      console.log([selectedRole, row.role]);

      if (selectedRole != "" && selectedRole != undefined) {
        newRoleOptions = [selectedRole, ...newRoleOptions];
      }
      setRoleOptions([...new Set(newRoleOptions)]);
      console.log(newRoleOptions);
    } else {
      toast.error(responseJson.message);
    }
    setLoading(false);
  }

  async function handleStore() {
    setLoading(true);
    let responseJson = {};
    if (isEdit) {
      responseJson = await apiPut(`${Routes.TENANTS}/${row.id}`, {
        name: name,
        roleName: selectedRole,
        isActive: isActive == true ? 1 : 0,
      });
    } else {
      responseJson = await apiPost(Routes.TENANTS, {
        name: name,
        roleName: selectedRole,
        isActive: isActive,
      });
    }

    if (responseJson.status) {
      toast.success(responseJson.message);
      handleList();
      resetForm();
    } else {
      toast.error(responseJson.message);
      setErrors(responseJson.errors);
    }
    setLoading(false);
  }

  function onChangeIaActiveHandler(){
    setIsActive((prev) => isActive == undefined? false :  !prev);
  }

  function onSelectHandler(roles) {
    setSelectedRole(roles.length > 0 ? roles[0] : "");

    let newErrors = {};
    if (roles.length < 1) {
      newErrors = {
        ...errors,
        roleName: [t(AppLangKeys.youMustSelectOneRole)],
      };
    } else {
      newErrors = {
        ...errors,
        roleName: [],
      };
    }
    setErrors(newErrors);
    return newErrors;
  }

  function onSubmit() {
    if (
      name.trim != "" &&
      (errors?.name?.length ?? 0) < 1 &&
      selectedRole != undefined &&
      selectedRole != ""
    ) {
      handleStore();
    }
  }

  return (
    <SaveCancelModel
      {...{ title, toggleHandler, isOpen, isEdit, onSubmit, loading }}
    >
      <form className="theme-form mega-form needs-validation" noValidate="">
        <div className="form-group">
          <label className="col-form-label">{t(AppLangKeys.tenantName)}</label>
          <input
            className="form-control mb-2"
            type="text"
            placeholder={t(AppLangKeys.tenantName)}
            value={name}
            onChange={(event) => onNameChangeHandler(event)}
          />
          <span>{errors?.name?.length > 0 && errors.name[0]}</span>
        </div>

        <div className="form-group">
          <Typeahead
            id="multiple-typeahead"
            clearButton
            defaultSelected={[row.role]}
            labelKey={t(AppLangKeys.choseRoles)}
            multiple={false}
            options={roleOptions}
            placeholder={t(AppLangKeys.choseRoles)}
            onChange={(roles) => onSelectHandler(roles)}
            onInputChange={(searchVal) =>
              handleListRoles({ ...queryParams, glopaleSearch: searchVal })
            }
          />
          <span>{errors?.roleName?.length > 0 && errors.roleName[0]}</span>
        </div>

        <div className="form-group">
          <div className="checkbox">
            <input
              id="isActive"
              type="checkbox"
              checked={isActive}
              onChange={onChangeIaActiveHandler}
            />
            <label htmlFor="isActive">{t(AppLangKeys.Active)}</label>
          </div>
        </div>
      </form>
    </SaveCancelModel>
  );
}

export default CreateTenant;
