
 const  AppLangKeys = {
    Dashboard : "Dashboard",
    Arabic : "Arabic",
    English : "English",
    Roles : "Roles",
    Permissions : "Permissions",
    Customers : "Customers",
    YourName: "Your Name",
    enterYourUsernameAndPassword:
    "Enter your Username and Password",
    LOGIN: "LOGIN",
    Password: "Password",

    Role: "Role",
    domain: "domain",
    Active: "Active",
    Action: "Action",
    Description: "Description",
    Name: "Name",
    ID: "ID",

    List: "List",
    Upload: "Upload",
    buttons: "Buttons",
    basicTable: "Basic Table",
    Editor: "Editor",
    Users: "Users",
    usersProfile: "Users Profile",
    usersEdit: "Users Edit",
    Email: "Email",
    Chat: "Chat",
    listView: "List View",
    Apply: "Apply",
    forgetPassword: "Forget Password",
    resetPassword: "Reset Password",
    saveChanges: "Save Changes",
    cancel: "Cancel",
    add: "Add",
    permissionNameMustBeGraterThan_3Letters: "Permission name must be grater than 3 letters",
    permissionName: "Permission name",
    choseRoles: "Chose roles",
    createPermission: "Create permission",
    list: "List",
    search: "Search ...",
    roleNameMustBeGraterThan_3Letters: "Role name must be grater than 3 letters",
    roleName: "Role name",
    chosePermission: "Chose permission",
    createRole: "Create role",
    tenants: "Tenants",
    createTenant: "Create tenant",

    tenantNameMustBeGraterThan_3Letters: "Tenant name must be grater than 3 letters",
    youMustSelectOneRole: "You must select one role",
    tenantName: "Tenant name",
    choseRoles: "Chose roles",
    Active: "Active",
    edit: "Edit",
    areYouSureYouWantDelete: "Are you sure you want delete",
    yes: "Yes",

    copyright_2022WicckbotAllRightsReserved: "Copyright 2022 wicckbot all rights reserved",
    wicckbotMadeWith: "Wicckbot made with",
    
    mustBe_50CharactersOrLess : "Must be 50 characters or less",
    mustBe_3CharactersAtLess : "Must be 3 characters at less",
    required : "Required",
    invalidEmailAddresss : "Invalid email addresss",
    phoneNumberIsNotValid : "Phone number is not valid",
    phoneNumberIsRequired : "Phone number is required",
    database : "Database",
}

export default AppLangKeys;