const DOMAIN = "https://api.wicckbot.com/";
const apiUrl = `${DOMAIN}api/`;
const apiAdminUrl = `${DOMAIN}api/admin/`;

export default {
    appVersion : '4.1.6',
    apiUrl: apiUrl,
    apiAdminUrl: apiAdminUrl,
    ROLES: `${apiAdminUrl}roles`,
    PERMISSIONS: `${apiAdminUrl}permissions`,
    TENANTS: `${apiAdminUrl}tenants`,
};

