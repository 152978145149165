import React from "react";
export const data = [
  {
    name: "Tiger",
    user: <span className="badge badge-primary">Tig</span>,
    avg_io: "0 KB/s",
    avg_cpu: "0%",
    avg_mem: "50 MB",
    action: (
      <div>
        <span>
          <i className="fa fa-trash" style={{ width: 35, fontSize: 16, padding: 11, color: "#e4566e" }}></i>
        </span>
        <span>
          <i className="fa fa-pencil" style={{ width: 35, fontSize: 16, padding: 11, color: "rgb(40, 167, 69)" }}></i>
        </span>
      </div>
    ),
  },
  {
    name: "Garrett",
    user: <span className="badge badge-secondary">Tig</span>,
    avg_io: "0.54 KB/s",
    avg_cpu: "0.20 %",
    avg_mem: "1 MB",
    action: (
      <div>
        <span>
          <i className="fa fa-trash" style={{ width: 35, fontSize: 16, padding: 11, color: "#e4566e" }}></i>
        </span>
        <span>
          <i className="fa fa-pencil" style={{ width: 35, fontSize: 16, padding: 11, color: "rgb(40, 167, 69)" }}></i>
        </span>
      </div>
    ),
  },
  {
    name: "Ashton",
    user: <span className="badge badge-primary">Tig</span>,
    avg_io: "20 KB/s",
    avg_cpu: "0.50 %",
    avg_mem: "13 MB",
    action: (
      <div>
        <span>
          <i className="fa fa-trash" style={{ width: 35, fontSize: 16, padding: 11, color: "#e4566e" }}></i>
        </span>
        <span>
          <i className="fa fa-pencil" style={{ width: 35, fontSize: 16, padding: 11, color: "rgb(40, 167, 69)" }}></i>
        </span>
      </div>
    ),
  },
  {
    name: "Cedric",
    user: <span className="badge badge-secondary">Tig</span>,
    avg_io: "50 KB/s",
    avg_cpu: "0.40 %",
    avg_mem: "25 MB",
    action: (
      <div>
        <span>
          <i className="fa fa-trash" style={{ width: 35, fontSize: 16, padding: 11, color: "#e4566e" }}></i>
        </span>
        <span>
          <i className="fa fa-pencil" style={{ width: 35, fontSize: 16, padding: 11, color: "rgb(40, 167, 69)" }}></i>
        </span>
      </div>
    ),
  },
  {
    name: "Airi",
    user: <span className="badge badge-primary">Tig</span>,
    avg_io: "0.40 KB/s",
    avg_cpu: "0 %",
    avg_mem: "13 MB",
    action: (
      <div>
        <span>
          <i className="fa fa-trash" style={{ width: 35, fontSize: 16, padding: 11, color: "#e4566e" }}></i>
        </span>
        <span>
          <i className="fa fa-pencil" style={{ width: 35, fontSize: 16, padding: 11, color: "rgb(40, 167, 69)" }}></i>
        </span>
      </div>
    ),
  },
  {
    name: "Brielle",
    user: <span className="badge badge-secondary">Tig</span>,
    avg_io: "25 KB/s",
    avg_cpu: "0.60 %",
    avg_mem: "25 MB",
    action: (
      <div>
        <span>
          <i className="fa fa-trash" style={{ width: 35, fontSize: 16, padding: 11, color: "#e4566e" }}></i>
        </span>
        <span>
          <i className="fa fa-pencil" style={{ width: 35, fontSize: 16, padding: 11, color: "rgb(40, 167, 69)" }}></i>
        </span>
      </div>
    ),
  },
  {
    name: "Herrod",
    user: <span className="badge badge-primary">Tig</span>,
    avg_io: "57 KB/s",
    avg_cpu: "0 %",
    avg_mem: "1 MB",
    action: (
      <div>
        <span>
          <i className="fa fa-trash" style={{ width: 35, fontSize: 16, padding: 11, color: "#e4566e" }}></i>
        </span>
        <span>
          <i className="fa fa-pencil" style={{ width: 35, fontSize: 16, padding: 11, color: "rgb(40, 167, 69)" }}></i>
        </span>
      </div>
    ),
  },
  {
    name: "Rhona",
    user: <span className="badge badge-secondary">Tig</span>,
    avg_io: "24 KB/s",
    avg_cpu: "0 %",
    avg_mem: "1 MB",
    action: (
      <div>
        <span>
          <i className="fa fa-trash" style={{ width: 35, fontSize: 16, padding: 11, color: "#e4566e" }}></i>
        </span>
        <span>
          <i className="fa fa-pencil" style={{ width: 35, fontSize: 16, padding: 11, color: "rgb(40, 167, 69)" }}></i>
        </span>
      </div>
    ),
  },
  {
    name: "Colleen",
    user: <span className="badge badge-primary">Tig</span>,
    avg_io: "0 KB/s",
    avg_cpu: "0 %",
    avg_mem: "14 MB",
    action: (
      <div>
        <span>
          <i className="fa fa-trash" style={{ width: 35, fontSize: 16, padding: 11, color: "#e4566e" }}></i>
        </span>
        <span>
          <i className="fa fa-pencil" style={{ width: 35, fontSize: 16, padding: 11, color: "rgb(40, 167, 69)" }}></i>
        </span>
      </div>
    ),
  },
  {
    name: "Sonya",
    user: <span className="badge badge-secondary">Tig</span>,
    avg_io: "0 KB/s",
    avg_cpu: "0 %",
    avg_mem: "13 MB",
    action: (
      <div>
        <span>
          <i className="fa fa-trash" style={{ width: 35, fontSize: 16, padding: 11, color: "#e4566e" }}></i>
        </span>
        <span>
          <i className="fa fa-pencil" style={{ width: 35, fontSize: 16, padding: 11, color: "rgb(40, 167, 69)" }}></i>
        </span>
      </div>
    ),
  },
  {
    name: "Jena",
    user: <span className="badge badge-primary">Tig</span>,
    avg_io: "0 KB/s",
    avg_cpu: "0 %",
    avg_mem: "1 MB",
    action: (
      <div>
        <span>
          <i className="fa fa-trash" style={{ width: 35, fontSize: 16, padding: 11, color: "#e4566e" }}></i>
        </span>
        <span>
          <i className="fa fa-pencil" style={{ width: 35, fontSize: 16, padding: 11, color: "rgb(40, 167, 69)" }}></i>
        </span>
      </div>
    ),
  },
  {
    name: "Quinn",
    user: <span className="badge badge-secondary">Tig</span>,
    avg_io: "75 KB/s",
    avg_cpu: "0.50 %",
    avg_mem: "42 MB",
    action: (
      <div>
        <span>
          <i className="fa fa-trash" style={{ width: 35, fontSize: 16, padding: 11, color: "#e4566e" }}></i>
        </span>
        <span>
          <i className="fa fa-pencil" style={{ width: 35, fontSize: 16, padding: 11, color: "rgb(40, 167, 69)" }}></i>
        </span>
      </div>
    ),
  },
  {
    name: "Charde",
    user: <span className="badge badge-primary">Tig</span>,
    avg_io: "30 KB/s",
    avg_cpu: "0.20 %",
    avg_mem: "10 MB",
    action: (
      <div>
        <span>
          <i className="fa fa-trash" style={{ width: 35, fontSize: 16, padding: 11, color: "#e4566e" }}></i>
        </span>
        <span>
          <i className="fa fa-pencil" style={{ width: 35, fontSize: 16, padding: 11, color: "rgb(40, 167, 69)" }}></i>
        </span>
      </div>
    ),
  },
  {
    name: "Haley",
    user: <span className="badge badge-secondary">Tig</span>,
    avg_io: "0.56 KB/s",
    avg_cpu: "0.10 %",
    avg_mem: "20 MB",
    action: (
      <div>
        <span>
          <i className="fa fa-trash" style={{ width: 35, fontSize: 16, padding: 11, color: "#e4566e" }}></i>
        </span>
        <span>
          <i className="fa fa-pencil" style={{ width: 35, fontSize: 16, padding: 11, color: "rgb(40, 167, 69)" }}></i>
        </span>
      </div>
    ),
  },
  {
    name: "Tatyana",
    user: <span className="badge badge-primary">Tig</span>,
    avg_io: "20 KB/s",
    avg_cpu: "0.80 %",
    avg_mem: "77 MB",
    action: (
      <div>
        <span>
          <i className="fa fa-trash" style={{ width: 35, fontSize: 16, padding: 11, color: "#e4566e" }}></i>
        </span>
        <span>
          <i className="fa fa-pencil" style={{ width: 35, fontSize: 16, padding: 11, color: "rgb(40, 167, 69)" }}></i>
        </span>
      </div>
    ),
  },
  {
    name: "Michael",
    user: <span className="badge badge-secondary">Tig</span>,
    avg_io: "0 KB/s",
    avg_cpu: "0.70 %",
    avg_mem: "30 MB",
    action: (
      <div>
        <span>
          <i className="fa fa-trash" style={{ width: 35, fontSize: 16, padding: 11, color: "#e4566e" }}></i>
        </span>
        <span>
          <i className="fa fa-pencil" style={{ width: 35, fontSize: 16, padding: 11, color: "rgb(40, 167, 69)" }}></i>
        </span>
      </div>
    ),
  },
  {
    name: "Paul",
    user: <span className="badge badge-primary">Tig</span>,
    avg_io: "0 KB/s",
    avg_cpu: "0.50 %",
    avg_mem: "11 MB",
    action: (
      <div>
        <span>
          <i className="fa fa-trash" style={{ width: 35, fontSize: 16, padding: 11, color: "#e4566e" }}></i>
        </span>
        <span>
          <i className="fa fa-pencil" style={{ width: 35, fontSize: 16, padding: 11, color: "rgb(40, 167, 69)" }}></i>
        </span>
      </div>
    ),
  },
  {
    name: "Gloria",
    user: <span className="badge badge-secondary">Tig</span>,
    avg_io: "65 KB/s",
    avg_cpu: "0.20 %",
    avg_mem: "1 MB",
    action: (
      <div>
        <span>
          <i className="fa fa-trash" style={{ width: 35, fontSize: 16, padding: 11, color: "#e4566e" }}></i>
        </span>
        <span>
          <i className="fa fa-pencil" style={{ width: 35, fontSize: 16, padding: 11, color: "rgb(40, 167, 69)" }}></i>
        </span>
      </div>
    ),
  },
  {
    name: "Bradley",
    user: <span className="badge badge-primary">Tig</span>,
    avg_io: "40 KB/s",
    avg_cpu: "0 %",
    avg_mem: "15 MB",
    action: (
      <div>
        <span>
          <i className="fa fa-trash" style={{ width: 35, fontSize: 16, padding: 11, color: "#e4566e" }}></i>
        </span>
        <span>
          <i className="fa fa-pencil" style={{ width: 35, fontSize: 16, padding: 11, color: "rgb(40, 167, 69)" }}></i>
        </span>
      </div>
    ),
  },
  {
    name: "Dai",
    user: <span className="badge badge-secondary">Tig</span>,
    avg_io: "10 KB/s",
    avg_cpu: "0 %",
    avg_mem: "15 MB",
    action: (
      <div>
        <span>
          <i className="fa fa-trash" style={{ width: 35, fontSize: 16, padding: 11, color: "#e4566e" }}></i>
        </span>
        <span>
          <i className="fa fa-pencil" style={{ width: 35, fontSize: 16, padding: 11, color: "rgb(40, 167, 69)" }}></i>
        </span>
      </div>
    ),
  },
  {
    name: "Jenette",
    user: <span className="badge badge-primary">Tig</span>,
    avg_io: "37 KB/s",
    avg_cpu: "0.30 %",
    avg_mem: "20 MB",
    action: (
      <div>
        <span>
          <i className="fa fa-trash" style={{ width: 35, fontSize: 16, padding: 11, color: "#e4566e" }}></i>
        </span>
        <span>
          <i className="fa fa-pencil" style={{ width: 35, fontSize: 16, padding: 11, color: "rgb(40, 167, 69)" }}></i>
        </span>
      </div>
    ),
  },
  {
    name: "Yuri",
    user: <span className="badge badge-secondary">Tig</span>,
    avg_io: "0 KB/s",
    avg_cpu: "0 %",
    avg_mem: "25 MB",
    action: (
      <div>
        <span>
          <i className="fa fa-trash" style={{ width: 35, fontSize: 16, padding: 11, color: "#e4566e" }}></i>
        </span>
        <span>
          <i className="fa fa-pencil" style={{ width: 35, fontSize: 16, padding: 11, color: "rgb(40, 167, 69)" }}></i>
        </span>
      </div>
    ),
  },
  {
    name: "Caesar",
    user: <span className="badge badge-primary">Tig</span>,
    avg_io: "0 KB/s",
    avg_cpu: "0 %",
    avg_mem: "20 MB",
    action: (
      <div>
        <span>
          <i className="fa fa-trash" style={{ width: 35, fontSize: 16, padding: 11, color: "#e4566e" }}></i>
        </span>
        <span>
          <i className="fa fa-pencil" style={{ width: 35, fontSize: 16, padding: 11, color: "rgb(40, 167, 69)" }}></i>
        </span>
      </div>
    ),
  },
  {
    name: "Doris",
    user: <span className="badge badge-secondary">Tig</span>,
    avg_io: "38 KB/s",
    avg_cpu: "0.10 %",
    avg_mem: "14 MB",
    action: (
      <div>
        <span>
          <i className="fa fa-trash" style={{ width: 35, fontSize: 16, padding: 11, color: "#e4566e" }}></i>
        </span>
        <span>
          <i className="fa fa-pencil" style={{ width: 35, fontSize: 16, padding: 11, color: "rgb(40, 167, 69)" }}></i>
        </span>
      </div>
    ),
  },
];

export const columns = [
  {
    name: "Name",
    selector: (row) => row.name,
    sortable: true,
    center: true,
  },
  {
    selector: (row) => row.user,
    name: "User",
    sortable: true,
    center: true,
  },
  {
    selector: (row) => row.avg_io,
    name: "Avg_io",
    sortable: true,
    center: true,
  },
  {
    selector: (row) => row.avg_cpu,
    name: "Avg_cpu",
    sortable: true,
    center: true,
  },
  {
    selector: (row) => row.avg_mem,
    name: "Avg_mem",
    sortable: true,
    center: true,
  },
  {
    selector: (row) => row.action,
    name: "Action",
    sortable: true,
    center: true,
  },
];
