import React from 'react';
import SweetAlert from 'sweetalert2';
import Switch from 'react-switch';
import { useTranslation } from 'react-i18next';
import AppLangKeys from '../../../localization/AppLangKeys';

export const TenantColumns = (page, perPage, handleDelete, toggleHandler, handleIsActiveUpdate) => {
  const { t } = useTranslation();
  
  function handleOnDelete(row) {
      SweetAlert.fire({
          title: t(AppLangKeys.areYouSureYouWantDelete) + ": " + row.name,
          cancelButtonText: t(AppLangKeys.cancel),
        confirmButtonText: t(AppLangKeys.yes),
          reverseButtons: true,
          showCancelButton: true,
          }).then((result) => {
              if (result.isConfirmed) {
                  handleDelete(row.id)
              }
          }
      );
  }




  return [
    {
      name: t("ID"),
      selector: (row, index) => {
          return <div>{((page-1) * perPage) + index + 1}</div>;
      },
      sortable: false,
      center: true,
      glow: 1,
    },
    {
      name: t("Name"),
      selector: (row) => row.name,
      sortable: true,
      center: false,
      glow: 3,
    },
    {
      name: t("Role"),
      selector: (row) => <span className="badge rounded-pill badge-warning mb-1">{row.role}</span>,
      sortable: false,
      center: false,
      glow: 3,
      class: ""
    },
    {
      name: t(AppLangKeys.database),
      selector: (row) => row.database,
      sortable: true,
      center: false,
      glow: 3,
    },
    {
      name: t("Active"),
      selector: (row) => (row.name != null && row.name != 'admin')? 
              <Switch
                id="rowIsActive"
                checked={row.isActive == 0? false : true}
                onChange={(checked)=>{handleIsActiveUpdate(checked, row.id)}}
              /> : "",
      sortable: true,
      center: false,
      glow: 1,
    },
    
    {
      name: t("Action"),
      selector: (row) => <div key={row.id}>
              {/* <a href='#' onClick={()=>handleOnDelete(row)}><span ><i className="fa fa-trash" style={{ width: 35, fontSize: 16, padding: 11, color: '#e4566e' }}></i></span></a> */}
              {(row.name != null && row.name != 'admin') && <a href='#' onClick={()=>{toggleHandler(true, row, `${t(AppLangKeys.edit)} ${row.name}`)}}><span><i className="fa fa-pencil" style={{ width: 35, fontSize: 16, padding: 11, color: 'rgb(40, 167, 69)' }}></i></span></a>}
          </div>,
      sortable: false,
      center: true,
      glow: 2,
    },
  ];
};
