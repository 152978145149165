import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";
import AppLangKeys from "./localization/AppLangKeys";
i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    // we init with resources
    resources: {
      en: {
        translations: {
          [AppLangKeys.Arabic]: "Arabic",
          [AppLangKeys.English]: "English",
          [AppLangKeys.Dashboard]: "Dashboard",
          [AppLangKeys.Roles]: "Roles",
          [AppLangKeys.Permissions]: "Permissions",
          [AppLangKeys.Customers]: "Customers",

          [AppLangKeys.YourName]: "Your Name",
          [AppLangKeys.enterYourUsernameAndPassword]:
            "Enter your Username and Password",
          [AppLangKeys.LOGIN]: "LOGIN",
          [AppLangKeys.Password]: "Password",

          [AppLangKeys.Name]: "Name",
          [AppLangKeys.Role]: "Role",
          [AppLangKeys.domain]: "domain",
          [AppLangKeys.Active]: "Active",
          [AppLangKeys.Description]: "Description",

          Default: "Default",
          "E-Commerce": "E-Commerce",
          University: "University",
          Bitcoin: "Bitcoin",
          Server: "Server",
          Project: "Project",
          Widgets: "Widgets",
          "General widget": "General widget",
          "Chart widget": "Chart widget",
          "UI-Elements": "UI-Elements",
          Avatars: "Avatars",
          Breadcrumb: "Breadcrumb",
          Grid: "Grid",
          "Helper-Classes": "Helper-Classes",
          Ribbons: "Ribbons",
          Shadow: "Shadow",
          Spinner: "Spinner",
          "State-color": "State-color",
          Steps: "Steps",
          "Tag and Pills": "Tag and Pills",
          Typography: "Typography",
          Base: "Base",
          Accordion: "Accordion",
          Alert: "Alert",
          Buttons: "Buttons",
          Carousel: "Carousel",
          Collapse: "Collapse",
          Datepicker: "Datepicker",
          Dropdown: "Dropdown",
          Modal: "Modal",
          Pagination: "Pagination",
          Popover: "Popover",
          Progressbar: "Progressbar",
          Rating: "Rating",
          Tabset: "Tabset",
          Timepicker: "Timepicker",
          Tooltip: "Tooltip",
          Typeahead: "Typeahead",
          Advance: "Advance",
          "Drag and Drop": "Drag and Drop",
          Dropzone: "Dropzone",
          "Image Cropper": "Image Cropper",
          "Ngx Toastr": "Ngx Toastr",
          "Owl-Carousel": "Owl-Carousel",
          "Range Slider": "Range Slider",
          Scrollable: "Scrollable",
          Sticky: "Sticky",
          SweetAlert: "SweetAlert",
          Tour: "Tour",
          Icons: "Icons",
          "Flag icon": "Flag icon",
          "Fontawesome Icon": "Fontawesome Icon",
          "Ico Icon": "Ico Icon",
          "Themify Icon": "Themify Icon",
          "Feather Icon": "Feather Icon",
          "Whether Icon": "Whether Icon",
          "Default Style": "Default Style",
          "Flat Style": "Flat Style",
          "Edge Style": "Edge Style",
          "Raised Style": "Raised Style",
          "Button Group": "Button Group",
          Forms: "Forms",
          "Form Controls": "Form Controls",
          "Form Validation": "Form Validation",
          "Base Inputs": "Base Inputs",
          "Checkbox & Radio": "Checkbox & Radio",
          "Input Groups": "Input Groups",
          "Mega Options": "Mega Options",
          "Form Default": "Form Default",
          "Ngx Wizard": "Ngx Wizard",
          Tables: "Tables",
          "Bootstrap Tables": "Bootstrap Tables",
          "Basic Table": "Basic Table",
          "Sizing Table": "Sizing Table",
          "Border Table": "Border Table",
          "Styling Table": "Styling Table",
          "Ngx-Datatables": "Ngx-Datatables",
          "Editing Table": "Editing Table",
          "Filter Table": "Filter Table",
          "Fullscreen Table": "Fullscreen Table",
          "Paging Table": "Paging Table",
          Selection: "Selection",
          "Smart Table": "Smart Table",
          Cards: "Cards",
          "Basic Card": "Basic Card",
          "Creative Card": "Creative Card",
          "Tabbed Card": "Tabbed Card",
          "Draggable Card": "Draggable Card",
          Timeline: "Timeline",
          "Timeline 1": "Timeline 1",
          "Timeline 2": "Timeline 2",
          Charts: "Charts",
          "Google Chart": "Google Chart",
          Chartjs: "Chartjs",
          Chartist: "Chartist",
          "Ngx-Chart": "Ngx-Chart",
          Map: "Map",
          "Google Map": "Google Map",
          "Leaflet Map": "Leaflet Map",
          "Users Cards": "Users Cards",
          Calender: "Calender",
          Blog: "Blog",
          "Blog Details": "Blog Details",
          "Blog Single": "Blog Single",
          "Social App": "Social App",
          "Job-Search": "Job-Search",
          "Card View": "Card View",
          "Job Detail": "Job Detail",
          Learning: "Learning",
          "Learning List": "Learning List",
          "Detail Course": "Detail Course",
          FAQ: "FAQ",
          Knowledgebase: "Knowledgebase",
          "Support Ticket": "Support Ticket",
          "To-Do": "To-Do",
          Ecommerce: "Ecommerce",
          Product: "Product",
          "Product page": "Product page",
          "Product list": "Product list",
          "Payment Details": "Payment Details",
          Invoice: "Invoice",
          "Order History": "Order History",
          Pricing: "Pricing",
          "Sample page": "Sample page",
          "Search Pages": "Search Pages",
          "Error Page": "Error Page",
          "Error 400": "Error 400",
          "Error 401": "Error 401",
          "Error 403": "Error 403",
          "Error 404": "Error 404",
          "Error 500": "Error 500",
          "Error 503": "Error 503",
          Authentication: "Authentication",
          "Login Simple": "Login Simple",
          "Login with Bg Image": "Login with Bg Image",
          "Login with Bg video": "Login with Bg video",
          "Register Simple": "Register Simple",
          "Register with Bg Image": "Register with Bg Image",
          "Register with Bg video": "Register with Bg video",
          "Unlock User": "Unlock User",
          "Coming Soon": "Coming Soon",
          "Coming Simple": "Coming Simple",
          "Coming with Bg Image": "Coming with Bg Image",
          "Coming with Bg video": "Coming with Bg video",
          Maintenance: "Maintenance",
          // ////////////////////////////
          // Add: "Add",
        },
      },
      ar: {
        translations: {
          [AppLangKeys.Arabic]: "العربية",
          [AppLangKeys.English]: "الإنجليزية",
          [AppLangKeys.Dashboard]: "لوحة القيادة",
          [AppLangKeys.Roles]: "الأدوار",
          [AppLangKeys.Permissions]: "أذونات",
          [AppLangKeys.Customers]: "المستأجرين",

          [AppLangKeys.YourName]: "اسمك",
          [AppLangKeys.enterYourUsernameAndPassword]:
            "أدخل اسم المستخدم وكلمة المرور الخاصة بك",
          [AppLangKeys.LOGIN]: "تسجيل الدخول",
          [AppLangKeys.Password]: "كلمة المرور",

          [AppLangKeys.ID]: "#",
          [AppLangKeys.Name]: "الاسم",
          [AppLangKeys.Role]: "الدور",
          [AppLangKeys.domain]: "النطاق",
          [AppLangKeys.Active]: "نشط",
          [AppLangKeys.Action]: "إجراء",
          [AppLangKeys.Description]: "الوصف",

          [AppLangKeys.List]: "قائمة",
          [AppLangKeys.Upload]: "رفع",
          [AppLangKeys.buttons]: "أزرار",
          [AppLangKeys.basicTable]: "الجدول الأساسي",
          [AppLangKeys.Editor]: "المحرر",
          [AppLangKeys.Users]: "المستخدمين",
          [AppLangKeys.usersProfile]: "ملف المستخدم",
          [AppLangKeys.usersEdit]: "تعديل المستخدمين",
          [AppLangKeys.Email]: "البريد الإلكتروني",
          [AppLangKeys.Chat]: "الدردشة",
          [AppLangKeys.listView]: "عرض القائمة",
          [AppLangKeys.Apply]: "تطبيق",
          [AppLangKeys.forgetPassword]: "نسيت كلمة المرور",
          [AppLangKeys.resetPassword]: "إعادة تعيين كلمة المرور",
          [AppLangKeys.saveChanges]: "حفظ التعديلات",
          [AppLangKeys.cancel]: "الغاء",
          [AppLangKeys.add]: "أضف",

          [AppLangKeys.permissionNameMustBeGraterThan_3Letters]:
            "يجب أن يكون اسم الصلاحية أكبر من 3 أحرف",
          [AppLangKeys.permissionName]: "اسم الصلاحية",
          [AppLangKeys.choseRoles]: "اختيار الأدوار",
          [AppLangKeys.createPermission]: "إنشاء صلاحية",
          [AppLangKeys.list]: "قائمة",
          [AppLangKeys.search]: "بحث ...",
          [AppLangKeys.roleNameMustBeGraterThan_3Letters]:
            "يجب أن يكون اسم الدور أكبر من 3 أحرف",
          [AppLangKeys.roleName]: "اسم الدور",
          [AppLangKeys.chosePermission]: "اختيار الصلاحية",
          [AppLangKeys.createRole]: "إنشاء دور",
          [AppLangKeys.tenants]: "المستأجرون",
          [AppLangKeys.createTenant]: "إنشاء مستأجر",

          [AppLangKeys.tenantNameMustBeGraterThan_3Letters]:
            "يجب أن يكون اسم المستأجر أكبر من 3 أحرف",
          [AppLangKeys.youMustSelectOneRole]:
            "يجب عليك اختيار دور واحد على الأقل",
          [AppLangKeys.tenantName]: "اسم المستأجر",
          [AppLangKeys.choseRoles]: "اختيار الأدوار",
          [AppLangKeys.edit]: "تحرير",

          [AppLangKeys.areYouSureYouWantDelete]: "هل أنت متأكد أنك تريد حذف",
          [AppLangKeys.yes]: "نعم",

          [AppLangKeys.copyright_2022WicckbotAllRightsReserved]:
            "حقوق النشر 2022 ويكبوت. جميع الحقوق محفوظة",
          [AppLangKeys.wicckbotMadeWith]: "ويكبوت مصنوع بواسطة",

          [AppLangKeys.mustBe_50CharactersOrLess]:
            "يجب أن يكون 50 حرفًا أو أقل",
          [AppLangKeys.mustBe_3CharactersAtLess]:
            "يجب أن يكون 3 أحرف على الأقل",
          [AppLangKeys.required]: "مطلوب",
          [AppLangKeys.invalidEmailAddresss]:
            "عنوان البريد الإلكتروني غير صالح",
          [AppLangKeys.phoneNumberIsNotValid]: "رقم الهاتف غير صالح",
          [AppLangKeys.phoneNumberIsRequired]: "رقم الهاتف مطلوب",
          [AppLangKeys.database]: "قاعدة البيانات",
        },
      },
    },
    fallbackLng: "en",
    debug: false,

    // have a common namespace used around the full app
    ns: ["translations"],
    defaultNS: "translations",

    keySeparator: false, // we use content as keys

    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
